.datePickerHeaderContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.datePickerHeaderSelects{
    display: flex;
    margin-top: 5px;
    gap: 1rem;
}

.datePickerHeaderSelects select{
    border-radius: 5px;
    cursor: pointer;
}