.wf-task{
    width: 150px;
    height: 70px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    user-select: none;
    font-size: .75rem;

}

.wf-main{
    border: 1px solid #ff9900;
    font-weight: bold;
}

.wf-main-drop-area{
    width: 150px;
    height: 70px;
    background: #ffffff;
    border: 1px solid grey;
    border-style: dashed;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    color: grey;
    user-select: none;
    transition: 0.3s ease;
}

.wf-drop-cover{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgb(222, 222, 222);
    display: flex;
    justify-content: center;
    align-items: center;
    color:gray;
    font-weight: normal;
}