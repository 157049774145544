body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* :root {
  --select-border: orange;
  --select-focus: orange;
  --select-arrow: yellow;
} */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
  interpolate-size: allow-keywords;
}
body {
  background-color: #fafafa;
}
a {
  text-decoration: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.react-time-picker {
  width: 100%;
  height: 40px;
  margin-top: 1.6rem;
  border-radius: 5px;
}

.react-datepicker-popper {
  z-index: 999999 !important;
}

.App-map {
  position: relative;
  height: calc(100% - 1rem);
  width: calc(100% - 1rem);
  transform: translate(-0.5rem, -0.5rem);
  position: absolute !important;
  border-radius: 10px;
}


@media (max-width: 500px) {
  .App-map{
    transform:none;
    width:100%;
  }
}

.react-datepicker-wrapper input {
  text-align: center;
}

.date-picker-active {
  background-color: white !important;
  border: none !important;
  box-shadow: 0px 0px 1.8rem rgba(0, 0, 0, 0.1) !important;
}

.date-picker-empty {
  background: transparent !important;
}

.date-invalid {
  border: 1px solid red !important;
}

.info-window {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.image-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-container img {
  width: 100%;
  object-fit: cover;
}

.data-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.marker {
  position: relative;
  display: flex;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.marker-image {
  width: 40px;
  height: 40px;
  background-color: #ff9900;
  border-radius: 50%;
  border: 1px solid white;
}

.marker p {
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
  color: white;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
}

.google-map-global-view {
  width: 100%;
  margin-top: 1rem;
  height: calc(100% - 5rem);
  border-radius: 10px;
}

.white-text {
  color: #fff;
}

.bold {
  font-weight: bold;
}


.display-none {
  display: none !important; ;
}