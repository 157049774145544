.honest-select-container {
  width: 100%;
  height: 40px;
  color: #454545;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #c3c0cc;
  transition: 0.5s ease;
  padding: 0 1rem;
  border: 2px solid red;
  font-size: 0.87rem;
}

.honest-select-container article {
  position: absolute;
  top: 40px;
}

.honest-options-container {
  height: fit-content;
  bottom: 115%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 0.416741px solid #e8e8e8;
  box-shadow: 0px 8.33482px 16.6696px rgba(0, 0, 0, 0.1);
  border-radius: 5.83438px;
  z-index: 9999999999999 !important;
  background-color: white;
  max-height: 200px;
  overflow-y: auto;
}

.honest-option {
  cursor: pointer;
  width: 100%;
  padding: .5rem 0;
  color: #454545;
  display: flex;
  background-color: white;
  align-items: center;
  border-radius: 5px;
  padding-left: 1em;
  z-index: 9999999999999 !important;
  transition: 0.13s ease-in;
}

.honest-option:hover {
  background-color: #ff9900;
  color: white;
  border: none;
}

.multi-container {
  width: 85%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.multi-container::-webkit-scrollbar {
  display: none;
}

.multi-single {
  height: 100%;
  border-radius: 8px;
  background-color: #e0dfe4;
  color: #454545;
  padding: 0 7px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
}

.multi-single svg {
  min-width: 15px !important;
  max-height: 15px !important;
  transition: 0.3s ease;
}

.multi-single svg:hover {
  transform: scale(1.3);
}

.icons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 3rem;
}

.icons-container span {
  display: flex;
  align-items: center;
  transition: all 0.4s ease;
}

.icons-container span:hover {
  transform: rotate(180deg);
  transition: all 0.3s ease;
}

.icons-container span svg {
  width: 1.1rem;
}

.icons-container svg {
  fill: none !important;
}

.close-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-search {
  border: none;
  outline: none;
  width: 100%;
  background-color: transparent;
}
