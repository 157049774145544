.toggle-wrapper {
  position: relative;
  width: 50px;
  height: 22px;
  border-radius: 9px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  margin-left: 1rem;
}

.toggle-orange {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  border-radius: 9px;
  background-color: #ff9900;
  transition: 0.3s linear;
}

.toggled {
  left: 0;
}

.toggle-true-icon {
  width: 100%;
  height: 100%;
  padding: 0 0.4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 9;
}

.toggle-true-icon svg {
  transform: scale(0.7);
}
.toggle-true-icon svg path {
  stroke: #fff !important;
}

.toggle-false-icon {
  position: absolute;
  left: 110%;
  top: 0;
  transform: scale(0.7);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
