.container {
  width: 100%;
  min-height: 500px;
  background: #ffffff;
  box-shadow: 0px 0px 24.9826px rgba(0, 0, 0, 0.1);
  border-radius: 12.4913px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.input-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 120px));
  gap: 0.8rem;
  margin-bottom: 2rem;
  justify-content: center;
}

.single-input {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff9900;
  border-radius: 10px;
  height: 40px;
  width: 120px;
  color: white;
  cursor: grab;
}

.single-input:active {
  cursor: grabbing;
}

.functions-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(113px, 110px));
  margin: 1rem 0;
  gap: 2rem;
  justify-content: center;
}

.single-function {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: 0.2s ease;
}

.functionText {
  background: #ffffff;
  border-top: 1px solid #ff9900;
  border-bottom: 1px solid #c3c0cc;
  border-radius: 10px;
  min-width: 120px;
  width: 100%;
  min-height: 100px;
  height: 100%;
  display: flex;
  font-size: 0.8rem;
  padding: 0 0.2rem;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 24.9826px rgba(0, 0, 0, 0.1);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.functionText p {
  max-width: 120px;
}

/* .single-function:active {
    cursor: grabbing;
  }
   */

.functionInputs {
  display: flex;
  gap: 1rem;
  padding: 0 1rem;
}

.functionOutputs {
  display: flex;
  gap: 1rem;
  padding: 0 1rem;
}

.singleFunctionInput {
  padding: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  border: 1px solid #ff9900;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: white;
  position: relative;
  transition: 0.2s ease;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.singleFunctionInput::after {
  content: '';
  position: absolute;
  width: 100%;
  bottom: -3px;
  left: 0;
  background-color: white;
  height: 10px;
}

.singleFunctionOutput {
  padding: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  border: 1px solid #c3c0cc;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  cursor: grab;
}

.singleFunctionOutput:active {
  cursor: grabbing;
}

.singleFunctionOutput::after {
  content: '';
  position: absolute;
  width: 100%;
  top: -3px;
  left: 0;
  background-color: white;
  height: 10px;
}

.output-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 120px));
  gap: 0.8rem;
  margin-top: 2rem;
  justify-content: center;
}

.single-output {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 10px;
  height: 40px;
  width: 120px;
  transition: 0.3s ease;
  cursor: pointer;
}
