.time-picker-display {
    width: 100%;
    height: 40px;
    color: #454545;
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #c3c0cc;
    transition: 0.5s ease;
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    }

    .display-active{
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      border: none;
      background-color: white;
    }
  
  .time-picker-options {
    position: absolute;
    top: 105%;
    left: 50%;
    transform: translateX(-50%);
    height: 300px;
    width: 100%;
    max-width: 200px;
    border-radius: 5px;
    border: 1px solid #c3c0cc;
    display: flex;
    overflow: hidden;
    background: white;
    z-index: 99;

  }
  
  .picker-container {
    width: 50%;
    max-height: 100%;
    overflow: scroll;
    padding: 0.2rem;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  .picker-container::-webkit-scrollbar {
    display: none;
  }
  
  .time-item {
    width: 100%;
    aspect-ratio: 1.5;
    color: #464255;
    border-radius: 5px;
    transition: 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .time-item:hover {
    color: white;
    background-color: #fcbf62;
  }

  .time-active{
    color: white;
    background-color: #ff9900;
  }
  